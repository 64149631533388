const grey200 = '#DCDCDC';

const storyButton = '#FFBF1C';
const yellow = '#FFC32C';
const secondaryBlue = '#1638A5';
const neutralBlack = '#262626';

const green = '#287580';
const coral = '#F17460';
const olive = '#95B158';

const storyPrimaryLight = '#FFF8E5';
const growthPrimaryLight = '#F1FFE6';
const challengesPrimaryLight = '#FFF0ED';

export const BaseColors = {
    accent: '#b53600',
    error: '#7e0000',
    warning: '#ffc107',
    success: green,

    black: neutralBlack,

    outline: grey200,
    // background: '#ffc107',       // Background color
    'on-background': neutralBlack, // Foreground color
    // 'surface': colors['custom-neutral-grey-200'], // Used for card backgrounds

    'custom-neutral-black': neutralBlack,
    'custom-neutral-grey-100': '#F6F6F6',
    'custom-neutral-grey-200': grey200,
    'custom-neutral-grey-300': '#E5E5E5',
    'custom-neutral-grey-400': '#C3C3C3',
    'custom-neutral-grey-600': '#777',
    'custom-neutral-grey-700': '#545454',
    'custom-neutral-grey-800': '#434343',
    'custom-neutral-grey-900': neutralBlack,

    // @see here
    // https://www.figma.com/design/w3DMtUByEl8Z3ZHTaD0gAs/Actvo-v2?node-id=39-9305&t=X3cUbzf5OKWPNNe1-0
    'custom-yellow': yellow,

    'secondary-blue': secondaryBlue,
    'secondary-blue-light': '#E7EBF8',

    'secondary-orange': '#FB8625',
    'secondary-orange-light': '#FEEDDE',

    'secondary-pink': '#8B2687',
    'secondary-pink-light': '#F8EAF0',

    'secondary-purple': '#55199E',
    'secondary-purple-light': '#EEE8F7',

    'secondary-red': '#B83159',

    'tertiary-pink': '#CF7298',
    'tertiary-purple': '#571FBA',

    'guide-outline': '#002E7D',

    'story-primary-light': storyPrimaryLight,
    'story-outline': '#FFC32B',
    'story-button': storyButton,
    'on-story-button': neutralBlack,

    'challenges-primary-light': challengesPrimaryLight,
    'challenges-outline': '#ffb0a0',
    'challenges-button': coral,
    'on-challenges-button': neutralBlack,

    'growth-primary-light': growthPrimaryLight,
    'growth-outline': '#c1d692',
    'growth-button': olive,
    'on-growth-button': neutralBlack,

    // ==================================
    //
    // Legacy colors setup by Kah
    //
    // TODO: Ideally in the near future, this colors should be deprecated in favour of the new ones listed above
    //
    // ==================================

    'custom-green-light': '#3A9994',
    'custom-green-mid': green,
    'custom-green-deep': '#2C4648',

    'custom-purple-mid': '#6C3C99',
    'custom-purple-deep': '#160F29',

    'custom-independence-value': '#E6E6C7', // used in values card
    'custom-achievement-value': '#99D5EF', // used in values card
    'custom-empathy-value': '#F8EE62', // used in values card
    'custom-adventure-value': '#FCBE9F', // used in values card
    'custom-creativity-value': '#D2B9CE', // used in values card
    'custom-harmony-value': '#D7F682', // used in values card

    'custom-red': '#c9252c',
    'custom-red-light': '#FBD9E3',
};

export const StoryThemeColors = {
    primary: yellow,
    'on-primary': neutralBlack,
    'primary-light': storyPrimaryLight,
    secondary: secondaryBlue,
    'navigation-outline': '#F08A24',
    button: storyButton,
    'on-button': neutralBlack,
};

export const ChallengePathThemeColors = {
    primary: coral,
    'on-primary': neutralBlack,
    'primary-light': challengesPrimaryLight,
    secondary: secondaryBlue,
    'navigation-outline': coral,
    button: coral,
    'on-button': neutralBlack,
};

export const GrowPathThemeColors = {
    primary: olive,
    'on-primary': neutralBlack,
    'primary-light': growthPrimaryLight,
    secondary: secondaryBlue,
    'navigation-outline': olive,
    button: olive,
    'on-button': neutralBlack,
};

export const storyTheme = {
    dark: false,
    colors: {
        ...BaseColors,
        ...StoryThemeColors,
    },
    variables: {
        'background-linear-gradient': 'linear-gradient(167deg, #FFC32B 0%, #FB8625 100%)',
        'background-linear-gradient-light': 'linear-gradient(167deg, #FFF 0%, #FEF2DC 100%)',
    },
};

export const challengePathTheme = {
    dark: false,
    colors: {
        ...{
            ...BaseColors,
            ...ChallengePathThemeColors,
        },
    },
    variables: {
        'background-linear-gradient': `linear-gradient(180deg, #FFB0A0 0%, #FF7E64 100%)`,
        'background-linear-gradient-light': `linear-gradient(180deg, #FFF 0%, ${ChallengePathThemeColors['primary-light']} 100%)`,
    },
};

export const growthPathTheme = {
    dark: false,
    colors: {
        ...{
            ...BaseColors,
            ...GrowPathThemeColors,
        },
    },
    variables: {
        'background-linear-gradient': `linear-gradient(180deg, #C1D692 0%, ${GrowPathThemeColors['primary']} 100%)`,
        'background-linear-gradient-light': `linear-gradient(180deg, #FFF 0%, ${GrowPathThemeColors['primary-light']} 100%)`,
    },
};

export default storyTheme;
