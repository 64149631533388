<template>
    <FutureAspirationsGuide />

    <v-container class="d-flex flex-column pa-0 ga-16">
        <GrowHomeHeading @click="scrollToAbout" />

        <!--
            Use a simple div instead of attaching the ref to the v-row,
            so scrolling works on the HTMLElement. Otherwise $el has to be used
        -->
        <div ref="about">
            <v-row class="pa-0 w-100 mb-16" no-gutters>
                <GrowHomeAbout @click="onGetStartedClick" />
            </v-row>
        </div>
    </v-container>


</template>

<script setup lang="ts">
    import { onBeforeUnmount, ref } from 'vue';

    import { useCanvasStore } from '@/store/canvas/store';

    import { UserActivityArea } from '@/api/types/userActivity';
    import { useActivityStore } from '@/store/activity/store';
    import { useIsDesktop } from '@/composables/useIsDesktop';
    import { useRouter } from 'vue-router';
    import GrowHomeAbout from '@/components/grow/home/GrowHomeAbout.vue';
    import GrowHomeHeading from '@/components/grow/home/GrowHomeHeading.vue';
    import FutureAspirationsGuide from '@/components/canvas/future-aspirations/FutureAspirationsGuide.vue';
    import { growPathRoute } from '@/router/routes';

    const about = ref<HTMLElement | null>(null);

    function scrollToAbout() {
        about.value?.scrollIntoView({ behavior: 'smooth' });
    }

    const { isDesktop } = useIsDesktop();

    // const store = useChallengePathStore();
    const canvasStore = useCanvasStore();
    const activityStore = useActivityStore();

    await canvasStore.load();
    // await store.load();

    await activityStore.addEntryActivity(UserActivityArea.PlanFuture);

    onBeforeUnmount(async () => {
        await activityStore.addExitActivity(UserActivityArea.PlanFuture);
    });

    const router = useRouter();
    const onGetStartedClick = async () => {
        // TODO CORE-193 - Re-enable this when the feature is ready
        // if (!currentChallengesStore.activeChallenges.length) {
        //     await router.push(challengeActivityRoute);
        //     return;
        // }
        await router.push(growPathRoute);
    };
</script>
