import { defineStore } from 'pinia';
import { fetchMeUser, fetchOrCreateUser } from '@/services/user/service';
import type { Ref } from 'vue';
import { toValue } from 'vue';
import type { User } from '@/api/types/user';
import { verify } from '@/store/verify';
import {
    DataState,
    makeDataState,
    setInIdle,
    setInLoading,
    setInSyncing,
} from '@/store/common/dataState';
import {
    type GetAccessToken,
    getAccessTokenSilentlyOrFail,
    makeAccessTokenState,
    setAccessTokenGenerator,
    type WithAccessToken,
} from '@/store/common/accessTokenState';
import type { UserJob } from '@/api/types/userJob';
import { userDisplayName } from '@/store/user/util';
import { hasGPFeature } from '@/store/user/userFeatureFlags';

export type UserStoreState = {
    user: User | null;
    state: DataState;
    error: any | null;
} & WithAccessToken;

export type UserContext = {
    user: User;
    accessToken: string;
};

export const useUsersStore = defineStore({
    id: 'user',
    state: (): UserStoreState => ({
        user: null,
        ...makeDataState(),
        ...makeAccessTokenState(),
    }),
    getters: {
        isLoaded(state): boolean {
            return !!state.user;
        },
        isLoading(state): boolean {
            return state.state === DataState.Loading;
        },
        hasCanvas(state): boolean {
            return !!state.user?.canvas_id;
        },
        hasCurriculum(state): boolean {
            return !!state.user?.curriculums.length;
        },
        userJobs(state): UserJob[] {
            return state.user?.user_jobs ?? [];
        },
        hasProfileBeenSet(state): boolean {
            return !!state.user?.profile_saved_by_user;
        },
        displayName(state): string | null {
            return state.user ? userDisplayName(state.user) : null;
        },
        firstName(state): string {
            if (!state.user || !state.user?.first_name) {
                return '';
            }

            return state.user.first_name;
        },
        hasGPFeature(state): boolean {
            return hasGPFeature(state.user);
        }
    },
    actions: {
        async firstLoadUser(userRef: Ref, getAccessToken: GetAccessToken) {
            setInLoading(this);
            console.info('Loading user...');

            setAccessTokenGenerator(this, getAccessToken);
            const accessToken = await getAccessTokenSilentlyOrFail(this);

            const user = toValue(userRef);
            this.user = await fetchOrCreateUser(toValue(user), accessToken);

            setInIdle(this);
        },

        /** A function to reload the user state */
        async synchroniseCurrentUser(): Promise<User | null> {
            setInSyncing(this);
            const { accessToken } = await this.makeContext();

            console.info('Syncing user...');

            verify(this.user, 'No user to reload');
            this.user = await fetchMeUser(accessToken);

            setInIdle(this);

            return this.user;
        },

        async makeContext(): Promise<UserContext> {
            const user = verify(useUsersStore().user, 'No user');
            const accessToken = await getAccessTokenSilentlyOrFail(this);

            return { user, accessToken };
        },
    },
});
