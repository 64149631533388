<template>
    <app-page-tabs>
        <app-page-header>
            <template #title>
                <span v-if="growItem">
                    Grow:&nbsp;
                    <span class="font-weight-600">{{ growItem.description }}</span>
                </span>
                <span v-else>Grow</span>
            </template>
        </app-page-header>

        <template #tutorial>
            <GrowPathTutorial @done="navigationStore.setNext" />
        </template>

        <template #vision> TODO</template>
        <template #preferences> TODO</template>
        <template #pathways> TODO</template>
        <template #activities> TODO</template>
        <template #sequence> TODO</template>
        <template #review> review</template>

        <app-page-tabs-footer />

        <PageActivity :area="UserActivityArea.GrowPath" />
    </app-page-tabs>
</template>

<script setup lang="ts">
    import { computed, onBeforeUnmount, onMounted } from 'vue';

    import { useCanvasStore } from '@/store/canvas/store';

    import { UserActivityArea } from '@/api/types/userActivity';
    import PageActivity from '@/components/common/PageActivity.vue';
    import { useActivityStore } from '@/store/activity/store';
    import { useNavigationStore } from '@/store/navigation/useNavigationStore';
    import { makeNavigationTabs } from '@/store/navigation/utils';
    import { makePrevious } from '@/components/common/app-page/types';
    import { useRoute } from 'vue-router';
    import GrowPathTutorial from '@/components/growth/path/GrowPathTutorial.vue';
    import { growHomeRoute } from '@/router/routes';

    const growItem = computed(() => {
        return navigationStore.ctx?.item ?? null;
    });

    const route = useRoute();

    const items = makeNavigationTabs(
        [
            {
                title: 'Tutorial',
                key: 'tutorial',
                footer: false,
                onSelect: async () => {
                    await navigationStore.clearContext();
                },
            },
            {
                title: 'Vision',
                key: 'vision',
                default: true,
                onSelect: async () => {
                    await navigationStore.clearContext();
                },
                footer: {
                    prev: makePrevious({
                        text: 'Return home',
                        to: growHomeRoute,
                    }),
                },
            },
            {
                title: 'Preferences',
                key: 'preferences',
                disabled: () => !growItem.value,
                footer: {
                    prev: {},
                },
            },
            {
                title: 'Pathways',
                key: 'pathways',
                disabled: () => {
                    return true;
                },
                footer: {
                    prev: {},
                },
            },
            {
                title: 'Activities',
                key: 'activities',
                disabled: () => {
                    return true;
                },
                footer: {
                    prev: {},
                },
            },
            {
                title: 'Sequence',
                key: 'sequence',
                disabled: () => {
                    return true;
                },
                footer: {
                    prev: {},
                },
            },
            {
                title: 'Review',
                key: 'review',
                disabled: () => {
                    return true;
                },
                footer: {
                    prev: {},
                },
            },
        ],
        route.query.tab as string,
    );

    const navigationStore = useNavigationStore();
    await navigationStore.init(items);

    const { setTutorial } = useNavigationStore();

    const canvasStore = useCanvasStore();
    const activityStore = useActivityStore();

    await canvasStore.load();

    onMounted(async () => {
        await setTutorial();

        await activityStore.addEntryActivity(UserActivityArea.GrowPath);
    });

    onBeforeUnmount(async () => {
        await activityStore.addExitActivity(UserActivityArea.GrowPath);
    });
</script>
