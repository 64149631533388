export enum RoutesName {
    Home = 'home',
    AuthCallback = 'auth-callback',

    Profile = 'profile',
    CareerHistory = 'ch',
    Welcome = 'welcome',
    GuideAdmin = 'admin/guide',

    Canvas = 'canvas',

    CanvasSkills = 'canvas-skills',
    CanvasPersonalStrength = 'canvas-personal-strength',

    CanvasPersonalityType = 'canvas-personality-type-upload',
    CanvasPersonalValues = 'canvas-personal-values',

    CanvasFutureAspirations = 'canvas-future-aspirations',

    CareerHistoryExperiences = 'canvas-learned-experiences',
    CanvasInlineGuideStory = 'canvas-inline-guide-story',

    Story = 'story',

    Plan = 'plan',

    ChallengesHome = 'challenges-home',
    ChallengesActivity = 'challenges-activity',
    ChallengePathways = 'challenge-pathways',

    GrowHome = 'grow-home',
    GrowPath = 'grow-path',

    SingleChallengeSequencing = 'challenge-sequencing',

    CareerPlan = 'career-plan',

    Assistant = 'intro-assistant',
    HowDoesItWork = 'how-does-it-work',
    Terms = 'terms-and-conditions',
    Privacy = 'privacy-policy',
}
