<template>
    <!--  The inline styles override vuetify behaviour of shrinking the <v-app-bar />  -->
    <v-navigation-drawer
        :temporary="true"
        elevation="0"
        class="py-8 app-desktop-navigation-overlay align-center justify-space-between d-flex"
        :class="responsiveClass"
    >
        <div class="w-100">
            <v-list-item class="mb-10 py-0 pr-1" v-if="isDesktop">
                <router-link :to="homeRoute">
                    <v-img :src="logoSrc" :height="41.512" :width="74" />
                </router-link>

                <template #append>
                    <!-- In mobile, the close icon is outside of this component -->
                    <v-btn
                        elevation="0"
                        icon
                        variant="plain"
                        size="x-small"
                        height="40"
                        width="40"
                        @click.stop="emit('close')"
                    >
                        <v-icon size="24">mdi-close</v-icon>
                    </v-btn>
                </template>
            </v-list-item>
            <v-list-item
                min-height="30"
                class="mb-0"
                :to="homeRoute"
                :active="urlStore.isHomePath"
                :disabled="!userStore.hasProfileBeenSet"
            >
                <div class="text-paragraph-lg font-weight-600 align-center d-flex">
                    <app-fa-icon class="mr-4" icon="home" />
                    Home
                </div>
            </v-list-item>

            <v-list-item
                min-height="30"
                class="mt-4 mb-0"
                :to="storyRoute"
                :active="urlStore.isStoryPath"
                :disabled="!userStore.hasProfileBeenSet"
            >
                <div class="text-paragraph-lg font-weight-600 align-center d-flex">
                    <app-fa-icon class="mr-4" icon="book" />
                    Story

                    <v-spacer />

                    <!--
                        Adding some padding to the expansion icon, so clicking closer to the icon expand/collapse
                        Otherwise the click is handled by the parent element.
                     -->
                    <span class="pl-10" @click.prevent.stop="storyExpanded = !storyExpanded">
                        <app-fa-icon
                            class="text-right"
                            :icon="storyExpanded ? 'chevron-up' : 'chevron-down'"
                        />
                    </span>
                </div>
            </v-list-item>

            <v-list class="ml-8 pb-0" v-if="storyExpanded">
                <v-list-item
                    min-height="26"
                    v-for="(item, index) in storyItems"
                    :to="item.to"
                    :active="isCurrent(item.to)"
                    :class="index === storyItems.length - 1 ? '' : 'mb-1'"
                >
                    <div class="text-paragraph-md">{{ item.title }}</div>
                </v-list-item>
            </v-list>

            <v-list-item
                min-height="30"
                class="mt-4 mb-0"
                :to="challengesHomeRoute"
                :active="urlStore.isChallengePath"
                :disabled="!userStore.hasProfileBeenSet"
            >
                <div class="text-paragraph-lg font-weight-600 align-center d-flex">
                    <app-fa-icon class="mr-4" icon="road" />
                    Solve

                    <v-spacer />

                    <!--
                        Adding some padding to the expansion icon, so clicking closer to the icon expand/collapse
                        Otherwise the click is handled by the parent element.
                     -->
                    <span
                        class="pl-10"
                        @click.prevent.stop="challengesExpanded = !challengesExpanded"
                    >
                        <app-fa-icon
                            class="text-right"
                            :icon="challengesExpanded ? 'chevron-up' : 'chevron-down'"
                        />
                    </span>
                </div>
            </v-list-item>

            <v-list class="ml-8 pb-0" v-if="challengesExpanded">
                <v-list-item
                    min-height="26"
                    v-for="(item, index) in challengeItems"
                    :to="item.to"
                    :active="isCurrent(item.to)"
                    :class="index === challengeItems.length - 1 ? '' : 'mb-1'"
                >
                    <div class="text-paragraph-md">{{ item.title }}</div>
                </v-list-item>
            </v-list>

            <v-list-item
                v-if="userStore.hasGPFeature"
                min-height="30"
                class="mb-0"
                :to="growHomeRoute"
                :active="urlStore.isGrowPath"
                :disabled="!userStore.hasProfileBeenSet"
            >
                <div class="text-paragraph-lg font-weight-600 align-center d-flex">
                    <app-fa-icon class="mr-4" icon="tree" />
                    Grow
                </div>
            </v-list-item>
        </div>

        <v-spacer />

        <!-- Note:
            Using a simple container instead of the #append slot,
            as the #append slot takes always a fixed space, and sometimes hides the main nav links if there is not
            much vertical space (e.g.: mobile)
        -->
        <div class="d-flex flex-column app-header-navigation-overlay-bottom-container">
            <v-divider class="my-6"></v-divider>
            <v-row :class="responsiveClass" no-gutters>
                <v-col cols="6" class="pa-0">
                    <v-list-item
                        href="mailto:support@actvo.ai"
                        class="text-none router-link text-paragraph-sm"
                    >
                        Contact Us
                    </v-list-item>
                </v-col>
                <v-col cols="6" class="pa-0">
                    <v-list-item :to="profileRoute" class="text-none router-link text-paragraph-sm">
                        Profile
                    </v-list-item>
                </v-col>
                <v-col cols="6" class="pa-0">
                    <v-list-item :to="privacyRoute" class="text-none router-link text-paragraph-sm">
                        Privacy Policy
                    </v-list-item>
                </v-col>
                <v-col cols="6" class="pa-0">
                    <v-list-item
                        @click="handleLogout"
                        class="text-none router-link text-paragraph-sm"
                    >
                        Logout
                    </v-list-item>
                </v-col>
                <v-col cols="6" class="pa-0">
                    <v-list-item :to="termsRoute" class="text-none router-link text-paragraph-sm">
                        Terms and Conditions
                    </v-list-item>
                </v-col>
            </v-row>
        </div>
    </v-navigation-drawer>
</template>

<script setup lang="ts">
    import { useUsersStore } from '@/store/user/store';
    import {
        challengeActivityRoute,
        challengePathwaysRoute,
        challengesHomeRoute,
        type DeclaredRoute,
        growHomeRoute,
        homeRoute,
        memorableExperienceRoute,
        personalityTypeRoute,
        personalStrengthRoute,
        personalValuesRoute,
        privacyRoute,
        profileRoute,
        skillsRoute,
        storyRoute,
        termsRoute,
    } from '@/router/routes';
    import { ref } from 'vue';

    import logoSrc from '@/assets/images/logo/actvo-logo.svg';
    import { useRoute } from 'vue-router';
    import { useAuth0 } from '@/auth/auth';
    import { useInlineGuideStore } from '@/store/inline-guide/store';
    import { makeLogoutHandle } from '@/components/utils';
    import { useIsDesktop } from '@/composables/useIsDesktop';
    import { useURLStore } from '@/store/navigation/useURLStore';

    const emit = defineEmits(['close']);

    const userStore = useUsersStore();

    const route = useRoute();

    const { isDesktop, responsiveClass } = useIsDesktop();

    const isCurrent = (r: DeclaredRoute) => {
        return r.name === route.name;
    };

    const storyItems = [
        { to: skillsRoute, title: 'Skills' },
        { to: memorableExperienceRoute, title: 'Memorable Experiences' },
        { to: personalValuesRoute, title: 'Values' },
        { to: personalStrengthRoute, title: 'Personal Strengths' },
        { to: personalityTypeRoute, title: 'Personality Test' },
    ];

    /** Init story expanded state based on the current route */
    const storyExpanded = ref(
        storyItems.some((item) => isCurrent(item.to)) || isCurrent(storyRoute),
    );

    const challengeItems = [
        { to: challengeActivityRoute, title: 'Solve Challenges' },
        { to: challengePathwaysRoute, title: 'Solve Pathways' },
    ];

    const urlStore = useURLStore();

    /** Init story expanded state based on the current route */
    const challengesExpanded = ref(useURLStore().isChallengePath);

    const { logout } = useAuth0();
    const store = useInlineGuideStore();
    const handleLogout = async () => {
        const _logout = makeLogoutHandle(store, logout);
        await _logout();
    };
</script>

<style scoped lang="scss">
    .app-desktop-navigation-overlay {
        transform: none !important;
        z-index: 2000 !important;
        left: 0 !important;
        border-right: 2px solid rgb(var(--v-theme-outline));
        backdrop-filter: blur(6px);
        padding: 32px 16px;

        &.desktop {
            background: rgba(255, 255, 255, 0.9);
            width: 464px !important;

            :deep(.v-navigation-drawer__content),
            :deep(.v-navigation-drawer__append) {
                width: 100%;
            }

            .app-header-navigation-overlay-bottom-container {
                position: absolute;
                bottom: 32px;
            }
        }

        &.mobile {
            :deep(.v-navigation-drawer__content) {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-start;
            }

            background: rgba(255, 255, 255, 0.98);
            width: 100% !important;
        }
    }

    :deep(.v-list-item__overlay) {
        border-radius: 8px;
    }
</style>
